<script>
import VueContentLoading from 'vue-content-loading';

export default {
  components: {
    VueContentLoading,
  },
};
</script>

<template>
  <div>
    <vue-content-loading :width="300" :height="380">
      <rect x="0" y="0" width="300" height="250" />
      <rect x="75" y="260" width="150" height="30" />
      <rect x="0" y="300" width="300" height="30" />
      <rect x="0" y="340" width="300" height="30" />
    </vue-content-loading>
  </div>
</template>